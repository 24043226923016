import React from "react";
import BoxWithSidePanel from "../common/template/BoxWithSidePanel";
import StyledInput from "../common/atomic/StyledInput";
import {
  faLanguage,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import OverflowPanel from "./OverflowPanel";
import { SearchOverflow } from "../../types/SearchOverflow";
import SearchPanel from "./SearchPanel";
import {
  definitionsEnabledState,
  translationsEnabledState,
} from "../../recoil/wordLookup";
import CountDownNumber from "./CountDownNumber";
import Chip from "../common/atomic/Chip";

const searchBoxPlaceholder: MultiLanguageString = {
  en: "enter an english word",
  es: "introduzca una palabra española",
};

interface BoxWithWordLookupProps {
  children: JSX.Element;
  selectedText: string;
}

const BoxWithWordLookup: React.FC<BoxWithWordLookupProps> = ({
  children,
  selectedText,
}) => {
  const [word, setWord] = React.useState("");
  const [apiCountDown, setApiCountdown] = React.useState(10);
  const [dataFetched, setDataFetched] = React.useState(false);
  const [definitionsEnabled, setDefinitionsEnabled] = useRecoilState(
    definitionsEnabledState
  );
  const [translationsEnabled, setTranslationsEnabled] = useRecoilState(
    translationsEnabledState
  );
  const [searchOverflows, setSearchOverflows] = React.useState<
    Array<SearchOverflow>
  >([]);

  const language = useRecoilValue(languageState);

  const timerId = React.useRef<NodeJS.Timer | undefined>();

  const countingDown = React.useMemo(() => {
    return (
      apiCountDown > 0 &&
      word !== "" &&
      (definitionsEnabled || translationsEnabled)
    );
  }, [apiCountDown, word, definitionsEnabled, translationsEnabled]);

  const tick = React.useCallback(() => {
    setApiCountdown((previousState) => previousState - 1);
  }, []);

  React.useEffect(() => {
    timerId.current = setInterval(tick, 100);
    if (!countingDown) {
      clearInterval(timerId.current);
    }
    return () => clearInterval(timerId.current);
  });

  const setWordAndResetCounter = React.useCallback((value: string) => {
    setApiCountdown(10);
    setWord(value);
  }, []);

  React.useEffect(() => {
    setWordAndResetCounter(selectedText);
  }, [selectedText, setWordAndResetCounter]);

  const addSearchOverflow = React.useCallback(
    (overflow: SearchOverflow) => {
      setSearchOverflows([...searchOverflows, overflow]);
    },
    [searchOverflows]
  );

  const clearSearchOverflows = React.useCallback(() => {
    setSearchOverflows([]);
  }, []);

  React.useEffect(() => {
    setWordAndResetCounter("");
    clearSearchOverflows();
  }, [language, setWordAndResetCounter, clearSearchOverflows]);

  const toggleTranslationsEnabled = React.useCallback(() => {
    setTranslationsEnabled((previous) => !previous);
  }, [setTranslationsEnabled]);

  const toggleDefinitionsEnabled = React.useCallback(() => {
    setDefinitionsEnabled((previous) => !previous);
  }, [setDefinitionsEnabled]);

  const sendRequest = apiCountDown === 0 && word !== "";

  const waitingForInput = !countingDown && !dataFetched;

  const isCountDownVisible = !dataFetched;

  return (
    <BoxWithSidePanel
      width={460}
      panelContent={
        <div className="w-[460px] p-[20px]">
          <div className="flex">
            <div className="flex-1">
              <Chip
                onClick={toggleDefinitionsEnabled}
                text="translations"
                icon={faMagnifyingGlass}
                selected={definitionsEnabled}
              />
            </div>
            <div className="flex-1">
              <Chip
                onClick={toggleTranslationsEnabled}
                text="definitions"
                icon={faLanguage}
                selected={translationsEnabled}
              />
            </div>
          </div>
          <div className="flex-1">
            <StyledInput
              value={word}
              setValue={setWordAndResetCounter}
              placeholder={searchBoxPlaceholder[language]}
            />
          </div>
          <CountDownNumber
            isCountDownVisible={isCountDownVisible}
            apiCountDown={apiCountDown}
          />
          <SearchPanel
            word={word}
            sendRequest={sendRequest}
            setDataFetched={setDataFetched}
            addSearchOverflow={addSearchOverflow}
            dataFetched={dataFetched}
            waitingForInput={waitingForInput}
            definitionsEnabled={definitionsEnabled}
            translationsEnabled={translationsEnabled}
          />
        </div>
      }
    >
      {searchOverflows.length > 0 ? (
        <BoxWithSidePanel
          width={460}
          panelContent={
            <OverflowPanel
              closePanel={clearSearchOverflows}
              searchOverflows={searchOverflows}
            />
          }
        >
          {children}
        </BoxWithSidePanel>
      ) : (
        children
      )}
    </BoxWithSidePanel>
  );
};

export default React.memo(BoxWithWordLookup);
