import React from "react";
import { useRecoilValue } from "recoil";
import { useCreateVocab } from "../../api/useVocab";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import StyledButton from "../common/atomic/StyledButton";
import StyledInput from "../common/atomic/StyledInput";
import EditWordData from "./EditWordData";

const ADD_WORD_HEADER: MultiLanguageString = {
  en: "Add a new word",
  es: "Añadir una nueva palabra",
};

const ADD_WORD: MultiLanguageString = {
  en: "Add Word",
  es: "Añadir Palabra",
};

const WORD: MultiLanguageString = {
  en: "word",
  es: "palabra",
};

export const AddWord: React.FC = () => {
  const language = useRecoilValue(languageState);
  const [word, setWord] = React.useState("");
  const [definitions, setDefinitions] = React.useState<Array<string>>([]);
  const [translations, setTranslations] = React.useState<Array<string>>([]);

  const clearState = React.useCallback(() => {
    setWord("");
    setDefinitions([]);
    setTranslations([]);
  }, []);

  const createVocab = useCreateVocab({ successCallback: clearState });

  const onSubmit = React.useCallback(() => {
    createVocab.mutate({
      word,
      definitions,
      translations,
    });
  }, [createVocab, translations, definitions, word]);

  return (
    <div className="w-full p-[20px] h-[100%]">
      <h1 className="text-xl font-bold">{ADD_WORD_HEADER[language]}</h1>
      <StyledInput
        value={word}
        setValue={setWord}
        placeholder={WORD[language]}
      />
      <EditWordData
        type="definitions"
        items={definitions}
        updateItems={setDefinitions}
      />
      <EditWordData
        type="translations"
        items={translations}
        updateItems={setTranslations}
      />
      <div className="mt-5">
        <StyledButton
          width={215}
          onClick={onSubmit}
          text={ADD_WORD[language]}
        />
      </div>
    </div>
  );
};

export default React.memo(AddWord);
