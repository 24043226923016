import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { accessTokenState } from "../recoil/login";

const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const accessToken = useRecoilValue(accessTokenState);
  return !!accessToken ? children : <Navigate to="/login" replace />;
};

export default React.memo(RequireAuth);
