import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import {
  CreateVocabRequest,
  DeleteVocabRequest,
  EditVocabRequest,
  GetVocabResponse,
  VocabResponse,
} from "../types/Vocab";
import { accessTokenState } from "../recoil/login";
import { useRecoilValue } from "recoil";
import { BASE_URL } from "../config";
import { queryClient } from "..";

const getVocab = async (accessToken: string): Promise<GetVocabResponse> => {
  const response = await fetch(BASE_URL + "/vocab", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.json();
};

export const useGetVocab = (): UseQueryResult<GetVocabResponse> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useQuery(["vocab"], () => getVocab(accessToken!), {
    enabled: !!accessToken,
  });
};

const createVocab = async (
  createVocabRequest: CreateVocabRequest,
  accessToken: string
): Promise<VocabResponse> => {
  const response = await fetch(BASE_URL + "/vocab", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(createVocabRequest),
  });
  return response.json();
};

export const useCreateVocab = (params: {
  successCallback?: () => void;
}): UseMutationResult<VocabResponse, unknown, CreateVocabRequest, unknown> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useMutation(
    (createVocabRequest: CreateVocabRequest) =>
      createVocab(createVocabRequest, accessToken!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vocab"]);
        !!params.successCallback && params.successCallback();
      },
    }
  );
};

const editVocab = async (
  editVocabRequest: EditVocabRequest,
  accessToken: string
): Promise<VocabResponse> => {
  const response = await fetch(BASE_URL + "/vocab/" + editVocabRequest.id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      word: editVocabRequest.word,
      definitions: editVocabRequest.definitions,
      translations: editVocabRequest.translations,
    }),
  });
  return response.json();
};

export const useEditVocab = (params: {
  successCallback?: () => void;
}): UseMutationResult<VocabResponse, unknown, EditVocabRequest, unknown> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useMutation(
    (editVocabRequest: EditVocabRequest) =>
      editVocab(editVocabRequest, accessToken!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vocab"]);
        !!params.successCallback && params.successCallback();
      },
    }
  );
};

const deleteVocab = async (
  deleteVocabRequest: DeleteVocabRequest,
  accessToken: string
): Promise<VocabResponse> => {
  const response = await fetch(BASE_URL + "/vocab/" + deleteVocabRequest.id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.json();
};

export const useDeleteVocab = (params: {
  successCallback?: () => void;
}): UseMutationResult<VocabResponse, unknown, DeleteVocabRequest, unknown> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useMutation(
    (deleteVocabRequest: DeleteVocabRequest) =>
      deleteVocab(deleteVocabRequest, accessToken!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vocab"]);
        !!params.successCallback && params.successCallback();
      },
    }
  );
};
