import React from "react";
import { useRecoilValue } from "recoil";
import { useEditVocab } from "../../api/useVocab";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import { Vocab } from "../../types/Vocab";
import StyledButton from "../common/atomic/StyledButton";
import EditWordData from "./EditWordData";

const UPDATE_WORD: MultiLanguageString = {
  en: "Update Word",
  es: "Actualiza Palabra",
};

interface EditWordProps {
  vocab: Vocab;
  stopEdit: () => void;
}
export const EditWord: React.FC<EditWordProps> = ({ vocab, stopEdit }) => {
  const language = useRecoilValue(languageState);
  const [definitions, setDefinitions] = React.useState(vocab.definitions);
  const [translations, setTranslations] = React.useState(vocab.translations);

  const editVocab = useEditVocab({ successCallback: stopEdit });

  const onSubmit = React.useCallback(() => {
    editVocab.mutate({
      id: vocab.id!,
      word: vocab.word,
      definitions,
      translations,
    });
  }, [editVocab, translations, definitions, vocab]);

  return (
    <div>
      <EditWordData
        type="definitions"
        items={definitions}
        updateItems={setDefinitions}
      />
      <EditWordData
        type="translations"
        items={translations}
        updateItems={setTranslations}
      />
      <div className="mt-5">
        <StyledButton
          width={215}
          onClick={onSubmit}
          text={UPDATE_WORD[language]}
        />
      </div>
    </div>
  );
};

export default React.memo(EditWord);
