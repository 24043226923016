import React from "react";
import { Vocab } from "../../types/Vocab";
import ViewWordData from "./ViewWordData";

interface ViewWordProps {
  vocab: Vocab;
}
export const ViewWord: React.FC<ViewWordProps> = ({ vocab }) => {
  return (
    <div>
      <ViewWordData type="definitions" items={vocab.definitions} />
      <ViewWordData type="translations" items={vocab.translations} />
    </div>
  );
};

export default React.memo(ViewWord);
