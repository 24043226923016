import React from "react";
import { useGetVocab } from "../../../api/useVocab";
import { Colour } from "../../../types/Theme";
import IconButton, { IconButtonProps } from "./IconButton";

interface SelectableTextProps {
  readonly selectText: (value: string) => void;
  readonly isLoading?: boolean;
  readonly text: string;
  readonly iconButtonProps?: IconButtonProps;
}

const ONLY_WHITESPACE_REGEX = /^\s+$/;

const SelectableText: React.FC<SelectableTextProps> = ({
  selectText,
  isLoading,
  text,
  iconButtonProps,
}) => {
  const getVocab = useGetVocab();
  React.useEffect(() => {
    document.addEventListener("selectionchange", () => {
      const selectedText = window.getSelection()?.toString();
      const isValid =
        !!selectedText && !ONLY_WHITESPACE_REGEX.test(selectedText);
      if (isValid) {
        selectText(selectedText.trim());
      }
    });
    return () => {
      //   document.removeEventListener("selectionchange");
      /* todo unsub */
    };
  }, [selectText]);

  const highlightedText = React.useMemo(() => {
    if (getVocab.data === undefined) {
      return text;
    } else {
      let updatedText = text;
      for (const vocab of getVocab.data) {
        updatedText = updatedText.replace(
          new RegExp(" " + vocab.word + " ", "g"),
          ` <span class="bg-red-200">${vocab.word}</span> `
        );
      }
      return updatedText;
    }
  }, [text, getVocab.data]);

  return (
    <div className="whitespace-pre-line">
      <div className="float-right">
        {iconButtonProps && (
          <IconButton
            {...iconButtonProps}
            isLoading={!!isLoading}
            colour={Colour.PRIMARY}
          />
        )}
      </div>
      <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
    </div>
  );
};

export default React.memo(SelectableText);
