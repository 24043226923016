import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface CountDownNumberProps {
  readonly isCountDownVisible: boolean;
  readonly apiCountDown: number;
}

const CountDownNumber: React.FC<CountDownNumberProps> = ({
  isCountDownVisible,
  apiCountDown,
}) => {
  return isCountDownVisible ? (
    <div className="grid items-center justify-center">
      <div
        className="
      flex flex-row p-2 m-2 float-right
       m-2 border-double border-4 border-black
       "
      >
        <div className="w-[20px]">
          <p>{apiCountDown}</p>
        </div>
        <div className="ml-2 w-[20px]">
          <FontAwesomeIcon icon={faClock} size="lg" />
        </div>
      </div>
    </div>
  ) : null;
};

export default React.memo(CountDownNumber);
