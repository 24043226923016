import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useGetLyrics } from "../../api/useLyrics";
import { Colour } from "../../types/Theme";
import SelectableText from "../common/atomic/SelectableText";

interface LyricsPanelProps {
  readonly setSelectedText: (word: string) => void;
}

const LyricsPanel: React.FC<LyricsPanelProps> = ({ setSelectedText }) => {
  const getLyrics = useGetLyrics();

  const getCurrentSongLyrics = React.useCallback(() => {
    getLyrics.refetch();
  }, [getLyrics]);

  const isLoading = React.useMemo(() => {
    return getLyrics.isFetching;
  }, [getLyrics]);

  const lyrics = React.useMemo(() => {
    if (!!getLyrics.data && !!getLyrics.data.failure) {
      return getLyrics.data.failure;
    } else if (getLyrics.data) {
      return getLyrics.data.lyrics;
    } else {
      return "";
    }
  }, [getLyrics.data]);

  return (
    <div className="flex justify-center">
      <div className="mb-3 w-[75%] mt-10">
        <SelectableText
          text={lyrics || ""}
          selectText={setSelectedText}
          isLoading={isLoading}
          iconButtonProps={{
            onClick: getCurrentSongLyrics,
            icon: faRefresh,
            colour: Colour.PRIMARY,
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(LyricsPanel);
