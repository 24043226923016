import {
  faCircleArrowLeft,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Colour } from "../../types/Theme";
import { Vocab } from "../../types/Vocab";
import IconButton from "../common/atomic/IconButton";
import DeleteWord from "./DeleteWord";
import EditWord from "./EditWord";
import ViewWord from "./ViewWord";

interface SelectedWordPanelProps {
  selectedWord: Vocab;
  unselectWord: () => void;
}
export const SelectedWordPanel: React.FC<SelectedWordPanelProps> = ({
  selectedWord,
  unselectWord,
}) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isEditing, setIsEditting] = React.useState(false);

  const onClickEditting = React.useCallback(() => {
    if (isDeleting) {
      setIsDeleting(false);
    } else {
      setIsEditting((previous) => !previous);
    }
  }, [isDeleting]);

  const initiateDelete = React.useCallback(() => {
    setIsEditting(false);
    setIsDeleting(true);
  }, []);

  const cancelDelete = React.useCallback(() => {
    setIsDeleting(false);
  }, []);

  const stopEdit = React.useCallback(() => {
    setIsEditting(false);
  }, []);

  React.useEffect(() => {
    setIsDeleting(false);
    setIsEditting(false);
  }, [selectedWord]);

  const content = React.useMemo(() => {
    if (isDeleting) {
      return (
        <DeleteWord
          vocab={selectedWord}
          cancelDelete={cancelDelete}
          onDelete={unselectWord}
        />
      );
    } else if (isEditing) {
      return <EditWord vocab={selectedWord} stopEdit={stopEdit} />;
    } else {
      return <ViewWord vocab={selectedWord} />;
    }
  }, [
    isDeleting,
    isEditing,
    cancelDelete,
    selectedWord,
    unselectWord,
    stopEdit,
  ]);

  return (
    <div className="w-full p-[20px] h-[100%]">
      <div className="flex">
        <div className="flex-1">
          <IconButton
            onClick={unselectWord}
            icon={faCircleArrowLeft}
            colour={Colour.PRIMARY}
          />
        </div>
        <div className="flex-1">
          <IconButton
            selected={isEditing}
            onClick={onClickEditting}
            colour={Colour.PRIMARY}
            icon={faEdit}
          />
        </div>
        <div className="flex-1">
          <IconButton
            onClick={initiateDelete}
            icon={faTrash}
            colour={Colour.WARNING}
          />
        </div>
      </div>
      <div className="text-xl font-bold mt-4">
        <h1>{selectedWord.word}</h1>
      </div>
      <div className="pb-10">{content}</div>
    </div>
  );
};

export default React.memo(SelectedWordPanel);
