import React from "react";

// const BUTTON_COLOURS: MultiThemeClass = {
//   light:
//     "bg-pink-800 hover:bg-pink-600" +
//     "focus:bg-pink-800 active:bg-pink-600 text-white",
//   dark:
//     "bg-pink-800 hover:bg-pink-600" +
//     "focus:bg-pink-800 active:bg-pink-600 text-white",
// };

interface StyledButtonProps {
  readonly onClick: () => void;
  readonly text: string;
  readonly width?: number;
}

const StyledButton: React.FC<StyledButtonProps> = ({
  onClick,
  text,
  width,
}) => {
  return (
    <button
      onClick={onClick}
      style={width ? { width: width + "px" } : {}}
      className={`
        inline-block 
        px-6 
        py-2.5
        font-medium 
        text-m
        leading-tight
        uppercase
        rounded
        shadow-lg
        hover:shadow-lg
        focus:shadow-lg
        focus:outline-none
        focus:ring-0
        active:shadow-lg
        transition
        duration-150
        ease-in-out
        bg-primary
        hover:bg-primaryFocussed
        `}
    >
      {text}
    </button>
  );
};

export default React.memo(StyledButton);
