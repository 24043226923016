export const fetchWithThrow: typeof fetch = (...args) => {
  return fetch(...args).then((res) => {
    if (res.ok) {
      return res;
    } else {
      console.log("throwing");
      return res.json().then((json) => Promise.reject(json));
    }
  });
};
