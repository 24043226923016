import { atom, selector } from "recoil";
import { LoginResponse } from "../types/User";

const localStorageValue = window.localStorage.getItem("loginResponse");
const loginResponseOnLoad =
  localStorageValue !== null ? JSON.parse(localStorageValue) : null;

export const loginReponseState = atom<LoginResponse | null>({
  key: "loginResponse",
  default: loginResponseOnLoad,
});

export const accessTokenState = selector<string | null>({
  key: "accessToken",
  get: (opts) => {
    const loginResponse = opts.get(loginReponseState);
    if (loginResponse === null) {
      return null;
    }
    if (Date.now() / 1000 > loginResponse.expiry) {
      return null;
    }
    return loginResponse.accessToken;
  },
});

export const saveLoginReponseToLocalStorage = (
  loginResponse: LoginResponse
): void => {
  window.localStorage.setItem("loginResponse", JSON.stringify(loginResponse));
};

export const removeLoginResponseFromLocalStorage = (): void => {
  window.localStorage.removeItem("loginResponse");
};
