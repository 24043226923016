import { useQuery, UseQueryResult } from "react-query";
import { useRecoilValue } from "recoil";
import { BASE_URL } from "../config";
import { accessTokenState } from "../recoil/login";
import { Language } from "../types/Language";

const getDefinitions = async (
  word: string,
  language: Language,
  accessToken: string
): Promise<Array<string>> => {
  const params = new URLSearchParams({ word });
  const response = await fetch(
    `${BASE_URL}/dictionary/definitions/${language}?${params.toString()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.json();
};

export const useGetDefinitions = (
  word: string,
  language: Language,
  enabled: boolean
): UseQueryResult<Array<string>> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useQuery(
    ["definitions", word, language],
    () => getDefinitions(word, language, accessToken!),
    {
      enabled: enabled && !!accessToken,
    }
  );
};

const getTranslations = async (
  word: string,
  language: Language,
  accessToken: string
): Promise<Array<string>> => {
  const response = await fetch(
    `${BASE_URL}/dictionary/translations/${language}?word=${word}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.json();
};

export const useGetTranslations = (
  word: string,
  language: Language,
  enabled: boolean
): UseQueryResult<Array<string>> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useQuery(
    ["translations", word, language],
    () => getTranslations(word, language, accessToken!),
    {
      enabled: enabled && !!accessToken,
    }
  );
};
