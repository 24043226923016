import React from "react";
import { useGetVocab } from "../../api/useVocab";
import PageWithTopBar from "../common/template/PageWithTopBar";
import BoxWithSidePanel from "../common/template/BoxWithSidePanel";
import VocabList from "./VocabList";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../common/atomic/IconButton";
import WordPanel from "./WordPanel";
import { Vocab } from "../../types/Vocab";
import { Colour } from "../../types/Theme";

const VocabComponent: React.FC = () => {
  const getVocab = useGetVocab();

  const [wordPanelOpen, setWordPanelOpen] = React.useState(false);
  const [selectedWord, setSelectedWord] = React.useState<Vocab | null>(null);

  const toggleWordPanelOpen = React.useCallback(() => {
    setWordPanelOpen((currentState) => !currentState);
  }, []);

  const unselectWord = React.useCallback(() => {
    setSelectedWord(null);
  }, []);

  const updateSelectedWord = React.useCallback(
    (vocab: Vocab) => {
      if (selectedWord?.id === vocab.id) {
        setSelectedWord(null);
      } else {
        setSelectedWord(vocab);
        setWordPanelOpen(true);
      }
    },
    [selectedWord?.id]
  );

  React.useEffect(() => {
    if (getVocab.data && selectedWord) {
      const updatedWord = getVocab.data.find(
        (vocab) => vocab.id === selectedWord.id
      );
      if (updatedWord) {
        setSelectedWord(updatedWord);
      }
    }
  }, [getVocab.data]);

  const content = React.useMemo(() => {
    if (!getVocab.data || !getVocab.isFetched) {
      return null;
    }
    return (
      <VocabList
        allVocab={getVocab.data}
        selectedWord={selectedWord}
        updateSelectedWord={updateSelectedWord}
      />
    );
  }, [getVocab.data, getVocab.isFetched, updateSelectedWord, selectedWord]);

  const wrappedContent = React.useMemo(() => {
    return wordPanelOpen ? (
      <BoxWithSidePanel
        width={400}
        panelContent={
          <WordPanel selectedWord={selectedWord} unselectWord={unselectWord} />
        }
      >
        {content}
      </BoxWithSidePanel>
    ) : (
      content
    );
  }, [wordPanelOpen, content, selectedWord, unselectWord]);

  return (
    <PageWithTopBar>
      <BoxWithSidePanel
        width={40}
        panelContent={
          <div className="flex h-[100%] justify-center items-center">
            <div>
              <IconButton
                onClick={toggleWordPanelOpen}
                icon={wordPanelOpen ? faChevronLeft : faChevronRight}
                colour={Colour.PLAIN}
              />
            </div>
          </div>
        }
      >
        {wrappedContent}
      </BoxWithSidePanel>
    </PageWithTopBar>
  );
};

export default React.memo(VocabComponent);
