import { useQuery, UseQueryResult } from "react-query";
import { GetVocabResponse } from "../types/Vocab";
import { accessTokenState } from "../recoil/login";
import { useRecoilValue } from "recoil";
import { BASE_URL } from "../config";

const getLyrics = async (
  accessToken: string
): Promise<{ failure?: string; lyrics?: string }> => {
  const response = await fetch(BASE_URL + "/music/current", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.json();
};

export const useGetLyrics = (): UseQueryResult<{
  failure?: string;
  lyrics?: string;
}> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useQuery(["lyrics"], () => getLyrics(accessToken!), {
    enabled: false,
  });
};
