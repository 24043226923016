import React from "react";
import { useRecoilValue } from "recoil";
import { useGetTranslations } from "../../api/useDictionary";
import { useCreateVocab } from "../../api/useVocab";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import { SearchOverflow } from "../../types/SearchOverflow";
import StyledButton from "../common/atomic/StyledButton";
import Definitions from "./Definitions";
import Translations from "./Translations";

const ADD_WORD: MultiLanguageString = {
  es: "Añadir palabra",
  en: "Add word",
};

const GET_STARTED: MultiLanguageString = {
  es: "Resalta o escribe una palabra para comenzar",
  en: "Highlight or type a word to get started",
};

const ENABLE_SEARCH: MultiLanguageString = {
  es: "Habilita definiciones o traducciones",
  en: "Enable definitions or translation",
};

const WORD_SAVED: MultiLanguageString = {
  es: "Se ha añadido la palabra!",
  en: "Word has been added!",
};

interface SearchPanelProps {
  readonly word: string;
  readonly sendRequest: boolean;
  readonly setDataFetched: (isFetched: boolean) => void;
  readonly addSearchOverflow: (overflow: SearchOverflow) => void;
  // TODO: this should be changed to data fetched successfully
  readonly dataFetched: boolean;
  readonly waitingForInput: boolean;
  readonly definitionsEnabled: boolean;
  readonly translationsEnabled: boolean;
}

const SearchPanel: React.FC<SearchPanelProps> = ({
  word,
  sendRequest,
  setDataFetched,
  addSearchOverflow,
  dataFetched,
  waitingForInput,
  definitionsEnabled,
  translationsEnabled,
}) => {
  const language = useRecoilValue(languageState);
  const getTranslations = useGetTranslations(word, language, sendRequest);

  React.useEffect(() => setDataFetched(getTranslations.isFetched), [
    setDataFetched,
    getTranslations.isFetched,
  ]);

  const [selectedTranslations, setSelectedTranslations] = React.useState<
    Array<string>
  >([]);
  const [selectedDefinitions, setSelectedDefinitions] = React.useState<
    Array<string>
  >([]);

  const [wordSaved, setWordSaved] = React.useState(false);

  const createVocab = useCreateVocab({
    successCallback: () => {
      setWordSaved(true);
      setSelectedTranslations([]);
      setSelectedDefinitions([]);
    },
  });

  React.useEffect(() => {
    setWordSaved(false);
  }, [word]);

  const addWordToVocab = React.useCallback(() => {
    createVocab.mutate({
      word,
      definitions: selectedDefinitions,
      translations: selectedTranslations,
    });
  }, [createVocab, selectedDefinitions, selectedTranslations, word]);

  if (!definitionsEnabled && !translationsEnabled) {
    return (
      <div className="mt-2">
        <p>{ENABLE_SEARCH[language]}</p>
      </div>
    );
  }
  return (
    <div>
      {definitionsEnabled && (
        <Definitions
          word={word}
          sendRequest={sendRequest}
          setDataFetched={setDataFetched}
          addSearchOverflow={addSearchOverflow}
          selectedDefinitions={selectedDefinitions}
          setSelectedDefinitions={setSelectedDefinitions}
        />
      )}
      {translationsEnabled && (
        <Translations
          word={word}
          sendRequest={sendRequest}
          setDataFetched={setDataFetched}
          addSearchOverflow={addSearchOverflow}
          selectedTranslations={selectedTranslations}
          setSelectedTranslations={setSelectedTranslations}
        />
      )}
      {dataFetched && !wordSaved && !createVocab.isLoading && (
        <div className="mt-6">
          <StyledButton
            width={200}
            text={ADD_WORD[language]}
            onClick={addWordToVocab}
          />
        </div>
      )}
      {wordSaved && (
        <div className="mt-6">
          <p>{WORD_SAVED[language]}</p>
        </div>
      )}
      {waitingForInput && (
        <div className="mt-2">
          <p>{GET_STARTED[language]}</p>
        </div>
      )}
    </div>
  );
};

export default React.memo(SearchPanel);
