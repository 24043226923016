import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { SearchOverflow, SearchType } from "../../types/SearchOverflow";
import { Colour } from "../../types/Theme";
import IconButton from "../common/atomic/IconButton";

interface SearchResultsProps {
  readonly word: string;
  readonly searchType: SearchType;
  readonly searchResults: Array<string> | undefined;
  readonly addSearchOverflow: (overflow: SearchOverflow) => void;
  readonly selectedSearchResults: Array<string>;
  readonly setSelectedSearchResults: (
    selectedDefintions: Array<string>
  ) => void;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  word,
  searchType,
  searchResults,
  addSearchOverflow,
  selectedSearchResults,
  setSelectedSearchResults,
}) => {
  const addResultsToOverflow = React.useCallback(() => {
    !!searchResults &&
      addSearchOverflow({
        word,
        searchResults,
        searchType,
      });
  }, [word, searchResults, searchType, addSearchOverflow]);

  const toggleResult = React.useCallback(
    (event) => {
      const clickedResult = event.target.value;
      if (selectedSearchResults.includes(clickedResult)) {
        setSelectedSearchResults(
          selectedSearchResults.filter((result) => result !== clickedResult)
        );
      } else {
        setSelectedSearchResults(
          [...selectedSearchResults, clickedResult].sort()
        );
      }
    },
    [setSelectedSearchResults, selectedSearchResults]
  );

  const SearchResultsPanel = React.useMemo(() => {
    if (!!searchResults) {
      return (
        <div>
          <div className="flex justify-center items-center">
            <div>
              <h1>
                {searchType} for <span className="font-bold">{word}</span>
              </h1>
            </div>
            <div>
              <IconButton
                onClick={addResultsToOverflow}
                icon={faChevronCircleRight}
                colour={Colour.PLAIN}
              />
            </div>
          </div>
          {searchResults.map((result) => (
            <div
              key={result}
              className="
              grid grid-cols-6 gap-4
              p-2 mt-4
              bg-slate-100
              "
            >
              <div className="col-span-5">{result}</div>
              <div>
                <input
                  onClick={toggleResult}
                  type="checkbox"
                  className="h-6 w-6 rounded-full shadow"
                  value={result}
                />
              </div>
            </div>
          ))}
        </div>
      );
    }
  }, [toggleResult, addResultsToOverflow, searchType, searchResults, word]);

  return SearchResultsPanel || null;
};

export default React.memo(SearchResults);
