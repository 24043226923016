import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { SearchOverflow } from "../../types/SearchOverflow";
import { Colour } from "../../types/Theme";
import IconButton from "../common/atomic/IconButton";

interface OverflowPanelProps {
  readonly closePanel: () => void;
  readonly searchOverflows: Array<SearchOverflow>;
}

const OverflowPanel: React.FC<OverflowPanelProps> = ({
  closePanel,
  searchOverflows,
}) => {
  return (
    <div className="w-full p-5">
      <div>
        <IconButton
          onClick={closePanel}
          icon={faChevronLeft}
          colour={Colour.PLAIN}
        />
      </div>
      <div>
        {searchOverflows.map((overflow) => {
          return (
            <div
              className="
              p-2 mt-4
              bg-slate-100
              "
              key={"overflow" + overflow.word}
            >
              <h1>
                {overflow.searchType} for{" "}
                <span className="font-bold">{overflow.word}</span>
              </h1>
              <ul>
                {overflow.searchResults.map((result) => (
                  <li key={result}>- {result}</li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(OverflowPanel);
