import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useLogin } from "../api/useLogin";
import { accessTokenState } from "../recoil/login";
import BoxWithSidePanel from "./common/template/BoxWithSidePanel";
import StyledButton from "./common/atomic/StyledButton";
import StyledInput from "./common/atomic/StyledInput";

const Login: React.FC = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const login = useLogin();

  const accessToken = useRecoilValue(accessTokenState);

  const onClick = React.useCallback(() => {
    login.mutate({
      username,
      password,
    });
  }, [username, password, login]);

  return !!accessToken ? (
    <Navigate to="/vocab" replace />
  ) : (
    <div className="h-screen grid justify-center items-center">
      <div className="h-min">
        <StyledInput
          setValue={setUsername}
          value={username}
          placeholder="Username"
        />
        <StyledInput
          setValue={setPassword}
          value={password}
          placeholder="Password"
          secret
        />
        <StyledButton onClick={onClick} text="Login" width={300} />
      </div>
    </div>
  );
};

export default React.memo(Login);
