import React from "react";
import { useRecoilValue } from "recoil";
import {
  useDeleteOAuthToken,
  useGetOAuthTokens,
  useOAuthRedirect,
} from "../../api/useOAuth";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import { OAuthProvider } from "../../types/OAuth";
import StyledButton from "../common/atomic/StyledButton";
import PageWithTopBar from "../common/template/PageWithTopBar";

const CONENCTED_TO_SPOTIFY: MultiLanguageString = {
  es: "Tu cuenta esta connectado a Spotify",
  en: "You account is connected to Spotify",
};

const NOT_CONENCTED_TO_SPOTIFY: MultiLanguageString = {
  es: "Tu cuenta no esta connectado a Spotify",
  en: "You account is not connected to Spotify",
};

const DELETE_SPOTIFY_TOKEN: MultiLanguageString = {
  es: "Borrar tu Spotify Token",
  en: "Delete your Spotify Token",
};

const GET_SPOTIFY_TOKEN: MultiLanguageString = {
  es: "Connectar tu Spotify",
  en: "Connect your Spotify",
};

const Account: React.FC = () => {
  const language = useRecoilValue(languageState);
  const getOauthTokens = useGetOAuthTokens();

  const deleteOAuthToken = useDeleteOAuthToken();
  const oAuthRedirect = useOAuthRedirect(OAuthProvider.Spotify, true);

  const deleteSpotifyToken = React.useCallback(() => {
    deleteOAuthToken.mutate(OAuthProvider.Spotify);
  }, [deleteOAuthToken]);

  const triggerOAuthRedirect = React.useCallback(() => {
    oAuthRedirect.data && location.replace(oAuthRedirect.data.redirectUrl);
  }, [oAuthRedirect.data]);

  const content = React.useMemo(() => {
    if (!getOauthTokens.isFetched) {
      return null;
    }
    if (getOauthTokens.isSuccess) {
      if (getOauthTokens.data.spotify) {
        return (
          <div className="grid items-center">
            <p className="mb-3">{CONENCTED_TO_SPOTIFY[language]}</p>
            <StyledButton
              onClick={deleteSpotifyToken}
              text={DELETE_SPOTIFY_TOKEN[language]}
            />
          </div>
        );
      } else {
        return (
          <div className="grid items-center">
            <p className="mb-3">{NOT_CONENCTED_TO_SPOTIFY[language]}</p>
            <StyledButton
              onClick={triggerOAuthRedirect}
              text={GET_SPOTIFY_TOKEN[language]}
            />
          </div>
        );
      }
    }
    if (getOauthTokens.isError) {
      <p>An error occured fetching oauth tokens</p>;
    }
  }, [deleteSpotifyToken, triggerOAuthRedirect, getOauthTokens, language]);

  return (
    <PageWithTopBar>
      <div className="grid h-full w-full place-items-center">{content}</div>
    </PageWithTopBar>
  );
};

export default React.memo(Account);
