import React from "react";
import { useRecoilValue } from "recoil";
import { useDeleteVocab } from "../../api/useVocab";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import { Vocab } from "../../types/Vocab";
import StyledButton from "../common/atomic/StyledButton";

const CONFIRM_DELETE_PROMPT: MultiLanguageString = {
  en: "Are you sure you want to permanently delete word",
  es: "Estas seguro q quieres borrar palabra",
};

const YES: MultiLanguageString = {
  en: "Yes",
  es: "Si",
};

const NO: MultiLanguageString = {
  en: "No",
  es: "No",
};

interface DeleteWordProps {
  vocab: Vocab;
  cancelDelete: () => void;
  onDelete: () => void;
}

const DeleteWord: React.FC<DeleteWordProps> = ({
  vocab,
  cancelDelete,
  onDelete,
}) => {
  const language = useRecoilValue(languageState);
  const deleteVocab = useDeleteVocab({ successCallback: onDelete });

  const onSubmit = React.useCallback(() => {
    !!vocab.id &&
      deleteVocab.mutate({
        id: vocab.id,
      });
  }, [vocab.id, deleteVocab]);

  return (
    <div>
      <h1 className="mt-5 m-1">
        {CONFIRM_DELETE_PROMPT[language]} &apos;
        {vocab.word}
        &apos; ?
      </h1>
      <div className="grid justify-center align-center">
        <div className="flex flex-row">
          <div className="m-2">
            <StyledButton width={80} text={YES[language]} onClick={onSubmit} />
          </div>
          <div className="m-2">
            <StyledButton
              width={80}
              text={NO[language]}
              onClick={cancelDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DeleteWord);
