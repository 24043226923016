import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import RequireAuth from "./components/RequireAuth";
import Vocab from "./components/Vocab";
import Music from "./components/Music";
import Analyse from "./components/Analyse";
import OAuthCallback from "./components/OAuthCallback";
import Read from "./components/Read";
import Account from "./components/Account";
import { useRecoilValue } from "recoil";
import { themeState } from "./recoil/theme";

function App(): JSX.Element {
  const theme = useRecoilValue(themeState);
  return (
    <div className={theme === "light" ? "light-mode" : "dark-mode"}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/analyse"
          element={
            <RequireAuth>
              <Analyse />
            </RequireAuth>
          }
        />
        <Route
          path="/music"
          element={
            <RequireAuth>
              <Music />
            </RequireAuth>
          }
        />
        <Route
          path="/read"
          element={
            <RequireAuth>
              <Read />
            </RequireAuth>
          }
        />
        <Route
          path="/account"
          element={
            <RequireAuth>
              <Account />
            </RequireAuth>
          }
        />
        <Route
          path="/callback"
          element={
            <RequireAuth>
              <OAuthCallback />
            </RequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Vocab />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
