import { useMutation, UseMutationResult } from "react-query";
import { useSetRecoilState } from "recoil";
import { BASE_URL } from "../config";
import { languageState } from "../recoil/language";
import { Language } from "../types/Language";
import {
  loginReponseState,
  saveLoginReponseToLocalStorage,
} from "../recoil/login";
import { LoginRequest, LoginResponse } from "../types/User";

const makeLoginRequest = async (
  loginRequest: LoginRequest
): Promise<LoginResponse> => {
  const response = await fetch(BASE_URL + "/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(loginRequest),
  });
  return response.json();
};

export const useLogin = (): UseMutationResult<
  LoginResponse,
  unknown,
  LoginRequest,
  unknown
> => {
  const setLoginResponse = useSetRecoilState(loginReponseState);
  const setLanguage = useSetRecoilState(languageState);
  return useMutation(makeLoginRequest, {
    onSuccess: (loginResponse: LoginResponse, loginRequest: LoginRequest) => {
      setLoginResponse(loginResponse);
      saveLoginReponseToLocalStorage(loginResponse);
      if (loginRequest.username === "sushi") {
        setLanguage(Language.Spanish);
      } else if (loginRequest.username === "cheesecake") {
        setLanguage(Language.English);
      }
    },
  });
};
