import {
  faLanguage,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useRecoilValue } from "recoil";
import { languageState } from "../../recoil/language";
import { Language } from "../../types/Language";

type WordDataType = "definitions" | "translations";

const title: Record<WordDataType, Record<Language, string>> = {
  definitions: {
    en: "definitions",
    es: "definiciones",
  },
  translations: {
    en: "translations",
    es: "traducciones",
  },
};

interface ViewWordDataProps {
  items: Array<string>;
  type: WordDataType;
}
export const ViewWordData: React.FC<ViewWordDataProps> = ({ items, type }) => {
  const language = useRecoilValue(languageState);
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="mt-3">
      <hr
        className="my-1 mx-auto w-full h-[2px] bg-gray-100 
          rounded border-0 md:my-5 dark:bg-gray-700"
      ></hr>
      <div className="mb-5 text-lg">
        <FontAwesomeIcon
          icon={type === "definitions" ? faMagnifyingGlass : faLanguage}
          size="lg"
        />
        <span className="ml-3">{title[type][language]}</span>
      </div>

      {items.map((item) => (
        <p
          key={item}
          className="flex justify-center items-center p-2 mt-4 bg-slate-100"
        >
          {item}
        </p>
      ))}
    </div>
  );
};

export default React.memo(ViewWordData);
