import React from "react";
import { useRecoilValue } from "recoil";
import { themeState } from "../../../recoil/theme";

// const INPUT_COLOURS: MultiThemeClass = {
//   light: "bg-white text-black",
//   dark: "bg-[#2B2C2C]text-white",
// };

interface StyledInputProps {
  readonly setValue: (value: string) => void;
  readonly value: string;
  readonly placeholder: string;
  readonly secret?: boolean;
}

const StyledInput: React.FC<StyledInputProps> = ({
  setValue,
  value,
  placeholder,
  secret,
}) => {
  const onChange = React.useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue]
  );

  return (
    <input
      onChange={onChange}
      value={value}
      type={secret ? "password" : "text"}
      className={`
        form-control
        block
        w-full
        mt-3
        mb-3
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white 
        focus:border-pink-800 focus:outline-none
        `}
      id={placeholder + "-input"}
      placeholder={placeholder}
    />
  );
};

export default React.memo(StyledInput);
