import React from "react";
import {
  loginReponseState,
  removeLoginResponseFromLocalStorage,
} from "../../../recoil/login";
import { useRecoilState, useSetRecoilState } from "recoil";
import StyledButton from "../atomic/StyledButton";
import LinkTab from "../atomic/LinkTab";
import { languageState } from "../../../recoil/language";
import { Language, MultiLanguageString } from "../../../types/Language";
import { Theme } from "../../../types/Theme";
import { themeState } from "../../../recoil/theme";

type Routes = "/vocab" | "/analyse" | "/music" | "/account" | "/read";

const TABS: Record<Routes, MultiLanguageString> = {
  "/vocab": {
    en: "Vocab",
    es: "Vocabulario",
  },
  "/analyse": {
    en: "Analyse",
    es: "Analizar",
  },
  "/music": {
    en: "Music",
    es: "Música",
  },
  "/account": {
    en: "Account",
    es: "Cuenta",
  },
  "/read": {
    en: "Read",
    es: "Leer",
  },
};

const SIGN_OUT_TEXT: MultiLanguageString = {
  en: "Sign Out",
  es: "Cerrar Sesión",
};

const PageWithTopBar: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [language, setLanguage] = useRecoilState(languageState);
  const [theme, setTheme] = useRecoilState(themeState);

  const toggleLanguage = React.useCallback(() => {
    setLanguage((currentLanguage) =>
      currentLanguage === Language.English ? Language.Spanish : Language.English
    );
  }, [setLanguage]);

  const toggleTheme = React.useCallback(() => {
    setTheme((currentTheme) =>
      currentTheme === Theme.LightMode ? Theme.DarkMode : Theme.LightMode
    );
  }, [setTheme]);

  const setLoginResponse = useSetRecoilState(loginReponseState);

  const onClick = React.useCallback(() => {
    setLoginResponse(null);
    removeLoginResponseFromLocalStorage();
  }, [setLoginResponse]);

  const languageImageSrc = React.useMemo(() => {
    switch (language) {
      case Language.English:
        return "/cheesecake-icon.png";
      case Language.Spanish:
        return "/sushi-icon.png";
    }
  }, [language]);

  const themeImageSrc = React.useMemo(() => {
    switch (theme) {
      case Theme.LightMode:
        return "/sun.png";
      case Theme.DarkMode:
        return "/moon.png";
    }
  }, [theme]);

  return (
    <div className="h-screen flex flex-col overflow-x">
      <div
        className="
        w-min-screen
        w-max-full
        flow-root
        bg-secondary
        border-b
    "
      >
        <div className="float-left m-3">
          <ul className="nav nav-tabs flex flex-col md:flex-row">
            <LinkTab to="/vocab" name={TABS["/vocab"][language]} />
            <LinkTab to="/analyse" name={TABS["/analyse"][language]} />
            <LinkTab to="/music" name={TABS["/music"][language]} />
            <LinkTab to="/read" name={TABS["/read"][language]} />
            <LinkTab to="/account" name={TABS["/account"][language]} />
          </ul>
        </div>

        <div className="float-right m-3">
          <StyledButton
            width={175}
            onClick={onClick}
            text={SIGN_OUT_TEXT[language]}
          />
        </div>
        <div className="float-right">
          <img
            src={languageImageSrc}
            onClick={toggleLanguage}
            style={
              language === Language.English
                ? {
                    height: "48px",
                  }
                : {
                    height: "42px",
                    marginLeft: "6px",
                  }
            }
            className="mt-2 mr-4 hover:opacity-70 hover:cursor-pointer"
          />
        </div>
        <div className="float-right">
          <img
            src={themeImageSrc}
            onClick={toggleTheme}
            style={{
              height: "44px",
            }}
            className="mt-2 mr-5 hover:opacity-70 hover:cursor-pointer"
          />
        </div>
      </div>
      <div className="h-1 flex flex-grow">{children}</div>
    </div>
  );
};

export default React.memo(PageWithTopBar);
