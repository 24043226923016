import React from "react";
import BoxWithWordLookup from "../BoxWithWordLookup";
import PageWithTopBar from "../common/template/PageWithTopBar";

const Read: React.FC = () => {
  return (
    <PageWithTopBar>
      <BoxWithWordLookup selectedText={""}>
        <div className="grid h-full w-full place-items-center">
          <p>Coming soon...</p>
        </div>
      </BoxWithWordLookup>
    </PageWithTopBar>
  );
};

export default React.memo(Read);
