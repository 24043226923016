import { atom } from "recoil";

export const definitionsEnabledState = atom<boolean>({
  key: "definitionsEnabled",
  default: true,
});

export const translationsEnabledState = atom<boolean>({
  key: "translationsEnabled",
  default: false,
});
