import {
  faAdd,
  faLanguage,
  faMagnifyingGlass,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useRecoilValue } from "recoil";
import { languageState } from "../../recoil/language";
import { Language } from "../../types/Language";
import { Colour } from "../../types/Theme";
import IconButton from "../common/atomic/IconButton";
import StyledTextArea from "../common/atomic/StyledTextArea";

type WordDataType = "definitions" | "translations";

const title: Record<WordDataType, Record<Language, string>> = {
  definitions: {
    en: "definitions",
    es: "definiciones",
  },
  translations: {
    en: "translations",
    es: "traducciones",
  },
};

interface EditWordDataProps {
  items: Array<string>;
  type: WordDataType;
  updateItems: (items: Array<string>) => void;
}
export const EditWordData: React.FC<EditWordDataProps> = ({
  items,
  type,
  updateItems,
}) => {
  const language = useRecoilValue(languageState);

  const addItem = React.useCallback(() => {
    const updatedItems = [...items];
    updatedItems.push("");
    updateItems(updatedItems);
  }, [items, updateItems]);

  const makeUpdateItem = React.useCallback(
    (index: number) => {
      return (value: string) => {
        const updatedItems = [...items];
        updatedItems[index] = value;
        updateItems(updatedItems);
      };
    },
    [items, updateItems]
  );

  const makeDeleteItem = React.useCallback(
    (index: number) => {
      return () => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        updateItems(updatedItems);
      };
    },
    [items, updateItems]
  );

  return (
    <div className="mt-3">
      <hr
        className="my-1 mx-auto w-full h-[2px] bg-gray-100 
            rounded border-0 md:my-5 dark:bg-gray-700"
      ></hr>
      <div className="mb-5 text-lg">
        <FontAwesomeIcon
          icon={type === "definitions" ? faMagnifyingGlass : faLanguage}
          size="lg"
        />
        <span className="ml-3">{title[type][language]}</span>
      </div>

      {items.map((item, index) => (
        <div key={index} className="mb-3 flex flex-row">
          <div className="w-full">
            <StyledTextArea
              value={item}
              setValue={makeUpdateItem(index)}
              placeholder="..."
              rows={2}
            />
          </div>
          <div className="w-[60px]">
            <IconButton
              onClick={makeDeleteItem(index)}
              icon={faTrash}
              colour={Colour.WARNING}
            />
          </div>
        </div>
      ))}
      <IconButton onClick={addItem} icon={faAdd} colour={Colour.PRIMARY} />
    </div>
  );
};

export default React.memo(EditWordData);
