export enum Theme {
  LightMode = "light",
  DarkMode = "dark",
}

export enum Colour {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  WARNING = "warning",
  PLAIN = "plain",
}
