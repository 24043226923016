import React from "react";
import { OAuthPostCallbackRequest } from "../../types/OAuth";
import PageWithTopBar from "../common/template/PageWithTopBar";
import CallbackHandler from "./CallbackHandler";

const OAuthCallback: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const state = queryParams.get("state");
  const authorizationCode = queryParams.get("code");

  const content = React.useMemo(() => {
    if (!state || !authorizationCode) {
      return <p>Invalid callback parameters</p>;
    } else {
      const request: OAuthPostCallbackRequest = {
        state,
        authorizationCode,
      };
      return <CallbackHandler request={request} />;
    }
  }, [state, authorizationCode]);

  return (
    <PageWithTopBar>
      <div className="flex justify-center items-center w-full">{content}</div>
    </PageWithTopBar>
  );
};

export default React.memo(OAuthCallback);
