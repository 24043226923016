import React from "react";

interface StyledTextAreaProps {
  readonly rows: number;
  readonly setValue: (value: string) => void;
  readonly value: string;
  readonly placeholder: string;
}

const StyledTextArea: React.FC<StyledTextAreaProps> = ({
  rows,
  setValue,
  value,
  placeholder,
}) => {
  const onChange = React.useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue]
  );

  return (
    <textarea
      onChange={onChange}
      className="
            form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white 
            focus:border-blue-600 focus:outline-none
          "
      id="exampleFormControlTextarea1"
      rows={rows}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default React.memo(StyledTextArea);
