import React from "react";
import { Navigate } from "react-router-dom";
import { useOAuthCallback } from "../../api/useOAuth";
import { OAuthPostCallbackRequest } from "../../types/OAuth";

interface CallbackHandlerProps {
  readonly request: OAuthPostCallbackRequest;
}

const CallbackHandler: React.FC<CallbackHandlerProps> = ({ request }) => {
  const oAuthCallback = useOAuthCallback(request);

  if (oAuthCallback.isError) {
    return <p>Callback failed: {JSON.stringify(oAuthCallback.error)}</p>;
  }

  if (oAuthCallback.isSuccess) {
    return <Navigate to="/account" />;
  }

  return null;
};

export default React.memo(CallbackHandler);
