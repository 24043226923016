import React from "react";
import { useRecoilValue } from "recoil";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import { Vocab } from "../../types/Vocab";
import StyledButton from "../common/atomic/StyledButton";
import StyledInput from "../common/atomic/StyledInput";
import AddWord from "./AddWord";
import SelectedWordPanel from "./SelectedWordPanel";

interface WordPanelProps {
  selectedWord: Vocab | null;
  unselectWord: () => void;
}

const ADD_WORD_HEADER: MultiLanguageString = {
  en: "Add a new word",
  es: "Añadir una nueva palabra",
};

const ADD_WORD: MultiLanguageString = {
  en: "Add Word",
  es: "Añadir Palabra",
};

const WORD: MultiLanguageString = {
  en: "word",
  es: "palabra",
};

export const WordPanel: React.FC<WordPanelProps> = ({
  selectedWord,
  unselectWord,
}) => {
  const [word, setWord] = React.useState(selectedWord?.word || "");

  const language = useRecoilValue(languageState);

  if (selectedWord !== null) {
    return (
      <SelectedWordPanel
        selectedWord={selectedWord}
        unselectWord={unselectWord}
      />
    );
  }

  return <AddWord />;
};

export default React.memo(WordPanel);
