import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { useRecoilValue } from "recoil";
import { queryClient } from "..";
import { BASE_URL } from "../config";
import { accessTokenState } from "../recoil/login";
import {
  DeleteTokenResponse,
  GetTokensResponse,
  OAuthGetRedirectResponse,
  OAuthPostCallbackRequest,
  OAuthPostCallbackResponse,
  OAuthProvider,
} from "../types/OAuth";
import { fetchWithThrow } from "./utils";

const getOAuthRedirect = async (
  oAuthProvider: OAuthProvider,
  accessToken: string
): Promise<OAuthGetRedirectResponse> => {
  const response = await fetchWithThrow(
    `${BASE_URL}/oauth/login?provider=${oAuthProvider}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.json();
};

export const useOAuthRedirect = (
  oAuthProvider: OAuthProvider,
  enabled: boolean
): UseQueryResult<OAuthGetRedirectResponse> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useQuery(
    ["redirect"],
    () => getOAuthRedirect(oAuthProvider, accessToken!),
    {
      enabled: enabled && !!accessToken,
    }
  );
};

const postOAuthCallback = async (
  callbackRequest: OAuthPostCallbackRequest,
  accessToken: string
): Promise<OAuthPostCallbackResponse> => {
  const response = await fetchWithThrow(`${BASE_URL}/oauth/callback`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(callbackRequest),
  });
  return response.json();
};

export const useOAuthCallback = (
  callbackRequest: OAuthPostCallbackRequest
): UseQueryResult<OAuthGetRedirectResponse> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useQuery(
    ["redirect"],
    () => postOAuthCallback(callbackRequest, accessToken!),
    {
      enabled: true,
      retry: false,
    }
  );
};

const getOAuthTokens = async (
  accessToken: string
): Promise<Record<OAuthProvider, boolean>> => {
  const response = await fetch(BASE_URL + "/oauth/tokens", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.json();
};

export const useGetOAuthTokens = (): UseQueryResult<GetTokensResponse> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useQuery(["tokens"], () => getOAuthTokens(accessToken!), {
    enabled: !!accessToken,
  });
};

const deleteOAuthToken = async (
  provider: OAuthProvider,
  accessToken: string
): Promise<DeleteTokenResponse> => {
  const response = await fetch(BASE_URL + "/oauth/tokens/" + provider, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.json();
};

export const useDeleteOAuthToken = (): UseMutationResult<
  DeleteTokenResponse,
  unknown,
  OAuthProvider,
  unknown
> => {
  const accessToken = useRecoilValue(accessTokenState);
  return useMutation(
    (provider: OAuthProvider) => deleteOAuthToken(provider, accessToken!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tokens"]);
      },
    }
  );
};
