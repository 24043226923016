import React from "react";
import { useRecoilValue } from "recoil";
import { useGetTranslations } from "../../api/useDictionary";
import { languageState } from "../../recoil/language";
import { SearchOverflow } from "../../types/SearchOverflow";
import SearchResults from "./SearchResults";

interface TranslationsProps {
  readonly word: string;
  readonly sendRequest: boolean;
  readonly setDataFetched: (isFetched: boolean) => void;
  readonly addSearchOverflow: (overflow: SearchOverflow) => void;
  readonly selectedTranslations: Array<string>;
  readonly setSelectedTranslations: (selectedDefintions: Array<string>) => void;
}

const Translations: React.FC<TranslationsProps> = ({
  word,
  sendRequest,
  setDataFetched,
  addSearchOverflow,
  selectedTranslations,
  setSelectedTranslations,
}) => {
  const language = useRecoilValue(languageState);
  const getTranslations = useGetTranslations(word, language, sendRequest);

  React.useEffect(() => setDataFetched(getTranslations.isFetched), [
    setDataFetched,
    getTranslations.isFetched,
  ]);

  return (
    <SearchResults
      searchType="translations"
      searchResults={getTranslations.data}
      word={word}
      addSearchOverflow={addSearchOverflow}
      selectedSearchResults={selectedTranslations}
      setSelectedSearchResults={setSelectedTranslations}
    />
  );
};

export default React.memo(Translations);
