import React from "react";
import { Link } from "react-router-dom";

interface LinkTabProps {
  readonly name: string;
  readonly to: string;
}

const LinkTab: React.FC<LinkTabProps> = ({ name, to }) => {
  return (
    <div className="w-[133px] grid place-items-center">
      <Link to={to}>
        <li className="nav-item" role="presentation">
          <p
            className="
          nav-link
          block
          font-medium
          text-xs
          uppercase
          px-6
          py-3
          rounded
          hover:border-transparent hover:bg-gray-100
          focus:border-transparent
        "
          >
            {name}
          </p>
        </li>
      </Link>
    </div>
  );
};

export default React.memo(LinkTab);
