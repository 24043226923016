import React from "react";

interface BoxWithSidePanelProps {
  readonly panelContent: JSX.Element;
  readonly children: JSX.Element | null;
  readonly width: number;
}

const BoxWithSidePanel: React.FC<BoxWithSidePanelProps> = ({
  panelContent,
  children,
  width,
}) => {
  return (
    <div
      className="
          flex 
          overflow-x 
          h-full min-h-full max-h-full
          w-full min-w-full max-w-full
        "
    >
      <div
        className={`
        h-full min-h-full max-h-full
        w-[${width}px] min-w-[${width}px] max-w-[${width}px]
        items-center 
        block
        shadow-xl
        text-center	
        overflow-y-auto
        border-r
        `}
      >
        {panelContent}
      </div>
      <div className="flex-1 min-w-[600px] h-full overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default React.memo(BoxWithSidePanel);
