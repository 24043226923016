export enum OAuthProvider {
  Spotify = "spotify",
}

export interface OAuthGetRedirectResponse {
  redirectUrl: string;
}

export interface OAuthPostCallbackRequest {
  state: string;
  authorizationCode: string;
}

export interface OAuthPostCallbackResponse {
  message: string;
}

export interface DeleteTokenResponse {
  message: string;
}

export type GetTokensResponse = Record<OAuthProvider, boolean>;
