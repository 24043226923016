import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface ChipProps {
  readonly onClick: () => void;
  readonly text: string;
  readonly selected: boolean;
  readonly icon?: IconProp;
}

export const Chip: React.FC<ChipProps> = ({
  text,
  onClick,
  selected,
  icon,
}) => {
  return (
    <div
      onClick={onClick}
      className={`m-2 p-3 rounded-full
      ${selected ? "bg-tertiary" : "bg-secondary"}
      ${selected ? "hover:bg-tertiaryFocussed" : "hover:bg-secondaryFocussed"}
      border-solid border-thin hover:cursor-pointer`}
    >
      {!!icon && <FontAwesomeIcon icon={icon} size="lg" />} {text}
    </div>
  );
};

export default React.memo(Chip);
