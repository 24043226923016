import React from "react";
import { useRecoilValue } from "recoil";
import { useGetOAuthTokens } from "../../api/useOAuth";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import BoxWithWordLookup from "../BoxWithWordLookup";
import PageWithTopBar from "../common/template/PageWithTopBar";
import LyricsPanel from "./LyricsPanel";
import SpotifyLogin from "./SpotifyLogin";

const SPOTIFY_REQUIRED: MultiLanguageString = {
  es: "Tienes que connectar tu cuenta de Spotify para usar esta pagina.",
  en: "You must connect your Spotify account in order to use this page.",
};

const ACCOUNT_PAGE: MultiLanguageString = {
  es: "Puedes hacerlo en la pagina Cuenta.",
  en: "You can do so on the Account page.",
};

const Music: React.FC = () => {
  const language = useRecoilValue(languageState);
  const getOauthTokens = useGetOAuthTokens();
  const [selectedText, setSelectedText] = React.useState("");

  const content = React.useMemo(() => {
    if (!getOauthTokens.isFetched) {
      return null;
    }
    if (getOauthTokens.isSuccess) {
      if (getOauthTokens.data.spotify) {
        return <LyricsPanel setSelectedText={setSelectedText} />;
      } else {
        return (
          <div className="grid h-full w-full place-items-center">
            <div>
              <p>{SPOTIFY_REQUIRED[language]}</p>
              <p>{ACCOUNT_PAGE[language]}</p>
            </div>
          </div>
        );
      }
    }
    if (getOauthTokens.isError) {
      <p>An error occured fetching oauth tokens</p>;
    }
  }, [language, getOauthTokens]);

  return (
    <PageWithTopBar>
      <BoxWithWordLookup selectedText={selectedText}>
        <div className="overflow-y-auto h-full">{content}</div>
      </BoxWithWordLookup>
    </PageWithTopBar>
  );
};

export default React.memo(Music);
