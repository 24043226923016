import React from "react";
import BoxWithWordLookup from "../BoxWithWordLookup";
import PageWithTopBar from "../common/template/PageWithTopBar";
import StyledButton from "../common/atomic/StyledButton";
import StyledTextArea from "../common/atomic/StyledTextArea";
import { MultiLanguageString } from "../../types/Language";
import { useRecoilValue } from "recoil";
import { languageState } from "../../recoil/language";
import SelectableText from "../common/atomic/SelectableText";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Colour } from "../../types/Theme";

const START_CTA: MultiLanguageString = {
  en: "Start",
  es: "Empieza",
};

const STOP_CTA: MultiLanguageString = {
  en: "Stop",
  es: "Parar",
};

const INTRODUCE_TEXT: MultiLanguageString = {
  en: "input your text here",
  es: "introduzca texto aqui",
};

const Analyse: React.FC = () => {
  const language = useRecoilValue(languageState);
  const [text, setText] = React.useState("");
  const [selectedText, setSelectedText] = React.useState("");
  const [isAnalyseMode, setIsAnalyseMode] = React.useState(false);

  const toggleAnalyse = React.useCallback(() => {
    setIsAnalyseMode((currentState) => !currentState);
  }, []);

  const content = React.useMemo(() => {
    return isAnalyseMode ? (
      <div className="flex justify-center">
        <div className="mb-3 w-[75%] mt-10">
          <SelectableText
            text={text}
            selectText={setSelectedText}
            iconButtonProps={{
              onClick: toggleAnalyse,
              icon: faPenToSquare,
              colour: Colour.PRIMARY,
            }}
          />
        </div>
      </div>
    ) : (
      <div className="flex justify-center">
        <div className="mb-3 w-[75%] mt-10">
          <StyledTextArea
            rows={20}
            value={text}
            setValue={setText}
            placeholder={INTRODUCE_TEXT[language]}
          />
          <div className="flex mt-5 justify-center">
            <StyledButton
              width={120}
              onClick={toggleAnalyse}
              text={START_CTA[language]}
            />
          </div>
        </div>
      </div>
    );
  }, [isAnalyseMode, language, text, setText, setSelectedText, toggleAnalyse]);

  console.log(selectedText);

  return (
    <PageWithTopBar>
      <BoxWithWordLookup selectedText={selectedText}>
        <>{content}</>
      </BoxWithWordLookup>
    </PageWithTopBar>
  );
};

export default React.memo(Analyse);
