import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Colour } from "../../../types/Theme";

export interface IconButtonProps {
  readonly icon: IconProp;
  readonly onClick: () => void;
  readonly isLoading?: boolean;
  readonly selected?: boolean;
  readonly colour: Colour;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  isLoading,
  colour,
}) => {
  return (
    <div
      onClick={onClick}
      className={`
      rounded-full p-3 m-2
      hover:cursor-pointer
      bg-${colour}
      hover:bg-${colour}Focussed`}
    >
      <div className={isLoading ? "rotate-loop" : ""}>
        <FontAwesomeIcon icon={icon} size="lg" />
      </div>
    </div>
  );
};

export default React.memo(IconButton);
