import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useRecoilValue } from "recoil";
import { languageState } from "../../recoil/language";
import { MultiLanguageString } from "../../types/Language";
import { Vocab } from "../../types/Vocab";
import Chip from "../common/atomic/Chip";
import StyledInput from "../common/atomic/StyledInput";

interface VocabListProps {
  allVocab: Array<Vocab>;
  selectedWord: Vocab | null;
  updateSelectedWord: (vocab: Vocab) => void;
}

const SEARCH_VOCAB: MultiLanguageString = {
  en: "search your vocab",
  es: "buscar tu vocabulario",
};

const getFilterText = (
  filterCount: number,
  totalCount: number
): MultiLanguageString => ({
  en: `showing ${filterCount} out of ${totalCount} words`,
  es: `mostrando ${filterCount} de ${totalCount} palabras`,
});

export const VocabList: React.FC<VocabListProps> = ({
  allVocab,
  selectedWord,
  updateSelectedWord,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const language = useRecoilValue(languageState);

  const filteredVocab = React.useMemo(() => {
    const sortedVocab = allVocab.sort((a, b) => a.word.localeCompare(b.word));
    return sortedVocab.filter((vocab) => vocab.word.includes(searchTerm));
  }, [searchTerm, allVocab]);

  const selectedWordId = React.useMemo(() => {
    return selectedWord?.id;
  }, [selectedWord]);

  return (
    <div className="overflow-y-hidden m-10">
      <div className="w-[100%]">
        <div
          className="flex flex-row content-between w-[100%]
          items-center
          "
        >
          <div className="mr-3">
            <FontAwesomeIcon icon={faSearch} size="lg" />
          </div>
          <div className="w-[400px]">
            <StyledInput
              setValue={setSearchTerm}
              value={searchTerm}
              placeholder={SEARCH_VOCAB[language]}
            />
          </div>
          <div className="w-[100%] text-right">
            <p>
              {getFilterText(filteredVocab.length, allVocab.length)[language]}
            </p>
          </div>
        </div>
        <div className="flex flex-row max-w-fit	flex-wrap mt-4">
          {filteredVocab.map((vocab) => (
            <Chip
              key={vocab.id}
              selected={vocab.id === selectedWordId}
              text={vocab.word}
              onClick={() => updateSelectedWord(vocab)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(VocabList);
